// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-articles-2020-year-in-review-js": () => import("./../../../src/pages/articles/2020-year-in-review.js" /* webpackChunkName: "component---src-pages-articles-2020-year-in-review-js" */),
  "component---src-pages-articles-full-time-freelance-designer-developer-js": () => import("./../../../src/pages/articles/full-time-freelance-designer-developer.js" /* webpackChunkName: "component---src-pages-articles-full-time-freelance-designer-developer-js" */),
  "component---src-pages-articles-js": () => import("./../../../src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-articles-reflection-on-making-100-k-freelancing-js": () => import("./../../../src/pages/articles/reflection-on-making-£100k-freelancing.js" /* webpackChunkName: "component---src-pages-articles-reflection-on-making-100-k-freelancing-js" */),
  "component---src-pages-business-js": () => import("./../../../src/pages/business.js" /* webpackChunkName: "component---src-pages-business-js" */),
  "component---src-pages-health-js": () => import("./../../../src/pages/health.js" /* webpackChunkName: "component---src-pages-health-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-newsletter-issue-1-js": () => import("./../../../src/pages/newsletter/issue-1.js" /* webpackChunkName: "component---src-pages-newsletter-issue-1-js" */),
  "component---src-pages-newsletter-issue-10-js": () => import("./../../../src/pages/newsletter/issue-10.js" /* webpackChunkName: "component---src-pages-newsletter-issue-10-js" */),
  "component---src-pages-newsletter-issue-11-js": () => import("./../../../src/pages/newsletter/issue-11.js" /* webpackChunkName: "component---src-pages-newsletter-issue-11-js" */),
  "component---src-pages-newsletter-issue-12-js": () => import("./../../../src/pages/newsletter/issue-12.js" /* webpackChunkName: "component---src-pages-newsletter-issue-12-js" */),
  "component---src-pages-newsletter-issue-13-js": () => import("./../../../src/pages/newsletter/issue-13.js" /* webpackChunkName: "component---src-pages-newsletter-issue-13-js" */),
  "component---src-pages-newsletter-issue-14-js": () => import("./../../../src/pages/newsletter/issue-14.js" /* webpackChunkName: "component---src-pages-newsletter-issue-14-js" */),
  "component---src-pages-newsletter-issue-2-js": () => import("./../../../src/pages/newsletter/issue-2.js" /* webpackChunkName: "component---src-pages-newsletter-issue-2-js" */),
  "component---src-pages-newsletter-issue-3-js": () => import("./../../../src/pages/newsletter/issue-3.js" /* webpackChunkName: "component---src-pages-newsletter-issue-3-js" */),
  "component---src-pages-newsletter-issue-4-js": () => import("./../../../src/pages/newsletter/issue-4.js" /* webpackChunkName: "component---src-pages-newsletter-issue-4-js" */),
  "component---src-pages-newsletter-issue-5-js": () => import("./../../../src/pages/newsletter/issue-5.js" /* webpackChunkName: "component---src-pages-newsletter-issue-5-js" */),
  "component---src-pages-newsletter-issue-6-js": () => import("./../../../src/pages/newsletter/issue-6.js" /* webpackChunkName: "component---src-pages-newsletter-issue-6-js" */),
  "component---src-pages-newsletter-issue-7-js": () => import("./../../../src/pages/newsletter/issue-7.js" /* webpackChunkName: "component---src-pages-newsletter-issue-7-js" */),
  "component---src-pages-newsletter-issue-8-js": () => import("./../../../src/pages/newsletter/issue-8.js" /* webpackChunkName: "component---src-pages-newsletter-issue-8-js" */),
  "component---src-pages-newsletter-issue-9-js": () => import("./../../../src/pages/newsletter/issue-9.js" /* webpackChunkName: "component---src-pages-newsletter-issue-9-js" */),
  "component---src-pages-newsletter-js": () => import("./../../../src/pages/newsletter.js" /* webpackChunkName: "component---src-pages-newsletter-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */)
}

